import * as React from 'react';
import styled from 'styled-components'; // tslint:disable-line

export const subHeader = `
  font-size: 1em;
  text-transform: uppercase;
  color: #aeaeae;
  letter-spacing: 0.084em;
  font-weight: normal;
`;

export const rowPadding = '0.83em 1.41em';
export const rowBorderBottom = '0.1em solid #eaf8f4';
export const horizontalPadding = 5;
export const fontSize = 3.18;
export const pageWidth = 210 / fontSize;
export const sheetPadding = horizontalPadding / fontSize;
export const contentWidth = pageWidth - (2 * sheetPadding);
 const avoidPageBreaking = `
  page-break-inside: avoid;
`;

export const Table = styled.div`
  width: ${contentWidth}em;
  margin-bottom: 3.33em;

  table {
    width: ${contentWidth}em;
    border-collapse: collapse;
    border-style: hidden;
    text-align: right;
    table-layout: fixed;
  }

  th {
    padding: ${rowPadding};
    font-weight: normal;
    color: #b8b8b8;
    border-bottom: ${rowBorderBottom};
    &.without-border {
      border-bottom: none;
    }
  }

  td {
    padding: ${rowPadding};
  }

  tbody {
    td {
      border-bottom: ${rowBorderBottom};
      &.without-border {
        border-bottom: none;
      }
    }
  }

  tfoot {
    ${avoidPageBreaking}
    display: table-row-group;
    th {
      padding: ${rowPadding};
      background-color: #ecf7ff;
      color: #000;
    }
  }
`;
export const halfContentWidth = contentWidth / 2;

export const SubHeader = styled.h3`
  ${subHeader}
`;

export const BlueTd = styled.td`
  background-color: #ecf7ff;
`;

export const clearfix = `
:after {
  content: "";
  display: table;
  clear: both;
}
`;

export const entryNumber = `
  width: 4.2em;
  color: #b8b8b8;
`;

export const correction = `
  width: 11em;
`;

export const col = `
  ${clearfix}
  float: left;
  width: ${halfContentWidth}em;
`;

export const Col = styled.div`
  ${col}
`;

export const EntryNumberTh = styled.th`
  ${entryNumber}
`;

export const DateWithLabel = styled.p`
  margin: 0.42em 0;
`;

export const Label = styled.span`
    overflow-wrap: break-word;
    color: #b1b1b1;
    min-width: 8.17em;
    display: inline-block;
    font-size: 1em;
`;

export const IssuerIdentity = styled.p`
  margin-top: 0;
  padding-left: 2.1em;
  line-height: 1.5em;
`;
export const NameSurname = styled.span`
    font-weight: bold;
`;

export const IssuerSignature = styled.div`
  display: inline-block;
  margin-top: 10em;
`;

export const Header = styled.div`
    :after {
        content: "";
        display: table;
        clear: both;
    }
`;

export const ContractSide = styled.div`
    font-style: normal;
    line-height: 1.41em;
    h4 {
        margin-bottom: 0;
    }
`;

export const Heading1 = styled.h1`
    font-size: 2em;
    font-weight: normal;
    margin: 0;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const EntryNetTh = styled.th`
  min-width: 7em;
`;

export const number = `
  text-align: left;
  padding-left: 0.9em;
  width: 15.5rem;
`;

export const date = `
  width: 4.3em;
  padding: 0 !important;
`;
