import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { Button } from 'components/src/components/DesignSystemV2/Elements/Buttons/Button/Button';
import { Spinner } from 'components/src/components/Elements/Spinner/Spinner';
import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import { Heading1 } from 'components/src/styles';
import * as queryString from 'query-string';
import * as React from 'react';
import { Wrapper } from '../components/Wrapper';
import { getPublicClientApplication } from './getPublicClientApplication';

const AUTH_DISABLED_PARAM_NAME = 'authDisabled';

interface LoadingOverlayProps {
  message?: React.ReactNode;
  visible?: boolean;
  className?: string;
  backgroundColor?: string;
  style?: object;
}

const Container = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.withoutDesign4};

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  const { visible, className, style, message, backgroundColor } = props;

  return visible ? (
    <Container
      className={className}
      style={style}
      backgroundColor={backgroundColor}
    >
      {message && (
        <Heading1 style={{ marginBottom: '1rem' }}>
          {message}
        </Heading1>
      )}
      <Spinner size={30}/>
    </Container>
  ) : null;
};

const loginInProgressMessage = 'Rozpoczęto logowanie w innym oknie przeglądarki';

export const AuthWrapper: React.FC = ({ children }) => {
  const msalInstance = getPublicClientApplication();

  const [loginInProgress, setLoginInProgress] = React.useState(false);
  const [authDisabled, setAuthDisabled] = React.useState(false);

  const [initializationSteps, setInitializationSteps] = React.useState({
    authDisabledSet: false,
  });

  const isInitializationComplete = () => Object.values(initializationSteps).every(step => step === true);

  const login = () => {
    setLoginInProgress(true);
    msalInstance.loginPopup({
      scopes: ['User.Read'],
    }).then(() => {
      location.href = '/';
    }).catch(() => {
      setLoginInProgress(false);
    });
  };

  React.useEffect(() => {
    const { authDisabled: authDisabledUrlParam } = queryString.parse(location.search);

    if (authDisabledUrlParam === 'true') {
      setAuthDisabled(true);
      sessionStorage.setItem(AUTH_DISABLED_PARAM_NAME, 'true');
    }

    if (authDisabledUrlParam === 'false') {
      setAuthDisabled(false);
      sessionStorage.setItem(AUTH_DISABLED_PARAM_NAME, 'false');
    }

    setInitializationSteps(prevState => ({
      ...prevState,
      authDisabledSet: true,
    }));

    if (msalInstance.getAllAccounts().length === 0
      && !authDisabled
      && sessionStorage.getItem(AUTH_DISABLED_PARAM_NAME) !== 'true'
    ) {
      login();
    }
  }, []);

  React.useEffect(() => {
    setAuthDisabled(sessionStorage.getItem(AUTH_DISABLED_PARAM_NAME) === 'true');

    setInitializationSteps(prevState => ({
      ...prevState,
      authDisabledSet: true,
    }));
  }, []);

  return (isInitializationComplete()
      ? (
        <MsalProvider
          instance={msalInstance}
        >
          {authDisabled
            ? (
              <Wrapper>
                <LoadingOverlay
                  visible={loginInProgress}
                  message={loginInProgressMessage}
                />
                {children}
              </Wrapper>
            )
            : (
              <>
                <AuthenticatedTemplate>
                  <Wrapper
                    onClickLogout={() => {
                      msalInstance.logout();
                    }}
                  >
                    <LoadingOverlay
                      visible={loginInProgress}
                      message={loginInProgressMessage}
                    />
                    {children}
                  </Wrapper>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Wrapper>
                    <LoadingOverlay
                      visible={loginInProgress}
                      message={loginInProgressMessage}
                    />
                    {!loginInProgress && msalInstance.getAllAccounts().length === 0 && (
                      <>
                        <br/>
                        <br/>
                        <br/>
                        <Button
                          onClick={() => {
                            login();
                          }}
                        >
                          Zaloguj się.
                        </Button>
                      </>
                    )}
                  </Wrapper>
                </UnauthenticatedTemplate>
              </>
            )
          }
        </MsalProvider>
      )
      : null
  );
};
