import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '9b777e69-9724-4b8f-9092-d15e314d7bb9',
    authority: 'https://login.microsoftonline.com/f4570777-8729-4ae8-8075-67818105e98e',
    // redirectUri: 'http://localhost:8051/',
    redirectUri: 'https://wb.acntx.tech',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export function getPublicClientApplication() {
  return new PublicClientApplication(msalConfig);
}
